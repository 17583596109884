<template>
	<router-view :key="$route.path" />
</template>

<script>
export default {
	data() {
		return {};
	},
	components: {},
};
</script>
